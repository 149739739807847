<script>
import CMExamConfig from '../../classes/CMExamConfig'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  mixins: [featureFlagsMixin],

  render() {
    return this.$scopedSlots.default({
      formData: this.formData,
      updateFormData: this.updateFormData
    })
  },
  data: () => ({
    formData: new CMExamConfig()
  }),
  methods: {
    updateFormData(data) {
      this.formData = {
        ...this.formData,
        ...data
      }
    }
  }
}
</script>
